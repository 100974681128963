<template>
  <div class="setting-change-password">
    <Header />
    <div class="current-account">{{currentAccount}}（当前登录账号）</div>
    <Form ref="form" :rows="rows" v-model="formData"/>
    <router-link to="/plainText/help">
      <div class="question-button">遇到问题？</div>
    </router-link>
    <Button class="submit-button" size="large" @click="handleSubmit">提交</Button>
  </div>
</template>

<script>
import * as user from '@/utils/user';
import Form from '@/components/common/form';
import Header from '@/components/header';
import Button from '@/components/common/button/CustomButton';
import { handleChangePassword } from '@/api/login';
export default {
  components: {
    Form,
    Header,
    Button,
  },
  data() {
    return {
      formData: {},
      currentAccount: user.getUserName(),
      rows: [
        {
          key: "old_pwd",
          label: "旧密码",
          type: "input",
          inputType: 'password',
          required: true,
        },
        {
          key: "new_pwd",
          label: "新密码",
          type: "input",
          inputType: 'password',
          required: true,
        },
        {
          key: "new_pwd_again",
          label: "确认新密码",
          type: "input",
          inputType: 'password',
          required: true,
        },
      ],
    }
  },
  methods: {
    async handleSubmit() {
      if(this.$refs.form.validate()){
        if (this.formData.new_pwd !== this.formData.new_pwd_again) {
          return this.$toast('两次密码输入不一致');
        }
        try {
          await handleChangePassword({ 
            oldPasd: this.formData.old_pwd, 
            newPasd: this.formData.new_pwd,
          });
          this.formData = {};
          this.$toast({
            message: '密码更新成功！',
            onClose: () => this.$router.back(),
          });
        } catch (e) {
          this.$toast(e.message);
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.setting-change-password {
  min-height: 100vh;
  background-color: #FAFBFC;
  .current-account {
    font-size: 12px;
    font-weight: 400;
    color: #818181;
    padding-left: 24px;
    line-height: 42px;
    text-align: left;
  }
  ::v-deep .input-wrap {
    width: 100%;
    .field {
      width: 100%;
      .van-field__control {
        text-align: left;
      }
    }
  }
  .question-button {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #CD001F;
    margin-top: 24px;
    padding-right: 18px;
    text-align: right;
  }
  .submit-button {
    width: 351px;
    border-radius: 10px;
    margin-top: 14px;
    font-weight: bold;
  }
}
</style>